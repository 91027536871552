import React, { memo } from 'react';
import { Grid, Heading, Section } from '@myci/ui-components';
import { withReducers } from 'redux-syringe';
import { o } from 'ramda';
import { useDispatch } from 'react-redux';
import { reducer as authentication, resetPassword } from '@myci/authentication';
import { Message } from '@myci/intl';
import { ResetPasswordForm, forms } from '@myci/module-authentication';

import m from '../messages';
import PublicLayout from '../layouts/PublicLayout';

const ForgottenPasword = () => {
	const dispatch = useDispatch();

	return (
		<PublicLayout
			headerClassName="section--forgotten-password"
			header={
				<Section pb={{ xs: 4, lg: 0 }} pt={4}>
					<Grid container>
						<Grid row>
							<Grid col={{ xs: 12, md: 6, lg: 5 }}>
								<Heading as="h1" mb={{ xs: 3, md: 4 }}>
									<Message {...m.resetPasswordAction} />
								</Heading>
							</Grid>
						</Grid>
					</Grid>
				</Section>
			}
		>
			<ResetPasswordForm
				onSubmit={values => {
					dispatch(resetPassword({ ...values, form: forms.resetPassword }));
				}}
			/>
		</PublicLayout>
	);
};

export default o(withReducers({ authentication }, { isGlobal: true }), memo)(ForgottenPasword);
